
<template>
 <vx-card>
   <div class="vx-row">
    <div class="vx-col md:w-full mt-4">
      <span style="font-weight: bold;">NB:</span> <span style="font-style: italic;"> Les champs suivis du signe (*) sont obligatoires pour l'enregistrement du centre hospitalier.</span>
    </div>
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Raison Sociale *" v-model="RaisonSoc" class="w-full" />
    </div>
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Adresse *"  v-model="AdrCli" class="w-full" />
    </div>
    <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input type="email" label="Email" v-model="MailCli" class="w-full" />
    </div>
   </div>
   <div class="vx-row">
     <div class="vx-col md:w-1/3 w-full mt-5">
        <vs-input label="Téléphone 1" v-model="TelCli1" class="w-full" />
     </div>
     <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Téléphone 2" v-model="TelCli2" class="w-full" />
     </div>
     <div class="vx-col md:w-1/3 w-full mt-5">
      <vs-input label="Téléphone 3" v-model="TelCli3" class="w-full" />
     </div>
   </div>
   <div class="vx-row">
      <div class="vx-col md:w-1/3 w-full mt-5">
        <vs-input label="Mobile 1" v-model="MobCli1" class="w-full" />
      </div>
      <div class="vx-col md:w-1/3 w-full mt-5">
        <vs-input label="Mobile 2" v-model="MobCli2" class="w-full" />
      </div>
      <div class="vx-col md:w-1/3 w-full mt-5">
        <vs-input label="Fax" v-model="FaxCli" class="w-full" />
      </div>
   </div>
   <div class="vx-row mt-base">
      <div class="vx-col text-right w-full ml-auto">
        <vs-button color="warning" type="border" class="mr-3 mb-2" @click="reset_data">Annuler</vs-button>
        <vs-button class="mb-2" :disabled="!validateForm" @click="saveCentreHospitalier" >Enregistrer</vs-button>
      </div>
    </div>
 </vx-card>

</template>

<script>

export default {
  data () {
    return {
      RaisonSoc: null,
      AdrCli: null,
      MailCli: null,
      TelCli1: null,
      TelCli2: null,
      TelCli3: null,
      MobCli1: null,
      MobCli2: null,
      FaxCli: null
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.RaisonSoc !== null && this.AdrCli !== null
    }
  },
  methods: {
    saveCentreHospitalier () {
      const payload = {
        RaisonSoc: this.RaisonSoc,
        AdrCli: this.AdrCli,
        MailCli: this.MailCli,
        TelCli1: this.TelCli1,
        TelCli2: this.TelCli2,
        TelCli3: this.TelCli3,
        MobCli1: this.MobCli1,
        MobCli2: this.MobCli2,
        FaxCli: this.FaxCli
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('centre_hospitalier/addCentreHospitalier', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.reset_data()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data () {
      this.RaisonSoc = null
      this.AdrCli = null
      this.MailCli = null
      this.TelCli1 = null
      this.TelCli2 = null
      this.TelCli3 = null
      this.MobCli1 = null
      this.MobCli2 = null
      this.FaxCli = null
    }
  },
  components: {}
}
</script>
